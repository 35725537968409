<template>
  <div class="tableList">
    <list-search :childData="childData" ></list-search>
     <global-table :tableField="tableField"  :tableData="tableData" ></global-table>
  </div>
</template>

<script>
import ListSearch from '@/components/actionList/ListSearch.vue'
import GlobalTable from '@/components/actionList/GlobalTable.vue'
import { SearchList, SearchData, tableField, tableData } from './js/searchlist'
export default {
  name: 'RepairDetailDialog',
  components: { ListSearch, GlobalTable },
  props: [''],
  data () {
    return {
      tableField: tableField,
      tableData: tableData,
      childData: { searchList: SearchList, searchData: SearchData }
    }
  },

  methods: {
  }
}
</script>
<style scoped>
.tableList{
padding: 0px;
}
</style>
